<template>
  <div>
    <b-modal id="singleDeletion" title="Dikkat!" @ok="deleteSingleItem">
      <p class="my-4">{{ deleteMessage }}</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>

    <b-modal id="singleApproval" title="Dikkat!" @ok="approveSingleItem">
      <p class="my-4">{{ approveMessage }}</p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>

    <b-modal id="multipleAction" title="Dikkat!" @ok="applyAction">
      <p class="my-4">
        Öğeleri {{ getActionName() }} istediğinizden emin misiniz?
      </p>
      <template #modal-footer="{ ok, cancel }">
        <b-button size="sm" variant="secondary" @click="cancel()">
          İptal
        </b-button>
        <b-button size="sm" variant="danger" @click="ok()"> Evet </b-button>
      </template>
    </b-modal>

    <div v-if="tableItems && tableItems.length == 0">
      <b-alert show variant="warning">Kayıt bulunamadı</b-alert>
    </div>
    <div v-else>
      <b-row>
        <b-col v-if="selectable">
          <p>
            <b-button size="sm" @click="selectAllRows" class="align-right">
              Tümünü Seç
            </b-button>
            <b-button size="sm" @click="clearSelected">Temizle</b-button>
          </p>
        </b-col>
        <b-col align-v="center" v-if="!infinitePageItems"
          ><div class="overflow-auto">
            <b-pagination
              v-model="currentPage"
              :total-rows="pageCount * maxTableItems"
              :per-page="maxTableItems"
              @change="handlePageChange"
              align="right"
            ></b-pagination></div
        ></b-col>
      </b-row>

      <b-table
        :per-page="perPage"
        :items="tableItems"
        :fields="tableFields"
        hover
        responsive
        ref="selectableTable"
        :selectable="selectable"
        @row-selected="onRowSelected"
      >
        <!-- Example scoped slot for select state illustrative purposes -->
        <template #cell(selected)="{ rowSelected }">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </template>

        <template #cell(cityWhereLocated)="row">
          <div>
            {{
              getCityName(
                cities.filter(
                  (e) =>
                    e.value == tableItems[row.index].contact.cityWhereLocated
                )[0]
              )
            }}
          </div>
        </template>

        <template #cell(category)="row">
          <div>
            {{
              tableItems[row.index].formOfParticipation == undefined
                ? row.item.category
                : tableItems[row.index].formOfParticipation == "sportState"
                ? [
                    tableItems[row.index].category,
                    tableItems[row.index].boatType,
                    tableItems[row.index].distance,
                  ]
                    .map(
                      (e) => getManagementItems.find((ele) => ele.id == e).title
                    )
                    .join("/") + " metre"
                : "Yok"
            }}
          </div>
        </template>

        <template #cell(branch)="row">
          <div>
            {{
              getManagementItems.find(
                (e) => e.id == tableItems[row.index].branch
              )
                ? getManagementItems.find(
                    (e) => e.id == tableItems[row.index].branch
                  ).title
                : ""
            }}
          </div>
        </template>

        <template #cell(approval)="row">
          <div>
            {{
              tableItems[row.index].system.approval == 0
                ? "Onaylanmamış"
                : "Onaylı"
            }}
          </div>
        </template>
        <template #cell(approved)="row">
          <div>
            {{
              tableItems[row.index].approved == false
                ? "Onaylanmamış"
                : "Onaylı"
            }}
          </div>
        </template>

        <template #cell(formOfParticipation)="row">
          <div>
            {{
              roleOptions.find(
                (e) => tableItems[row.index].formOfParticipation == e.value
              ).text
            }}
          </div>
        </template>

        <template #cell(clubregion)="row">
          <div>
            {{
              cities.find(
                (e) =>
                  (row.item.clubRel
                    ? row.item.clubRel.region
                    : row.item.personel1.contact.cityWhereLocated) == e.value
              ).text
            }}
          </div>
        </template>

        <template #cell(clubName)="row">
          <div style="width: 24rem">
            <router-link
              :to="{ name: 'ClubDetail', params: { id: row.item.id } }"
              v-text="tableItems[row.index].name"
            ></router-link>
          </div>
        </template>

        <template #cell(competitionName)="row">
          <div style="width: 24rem">
            <router-link
              :to="{ name: 'CompetitionDetail', params: { id: row.item.id } }"
              v-text="tableItems[row.index].name"
            ></router-link>
          </div>
        </template>

        <template #cell(name)="row">
          <div style="width: 24rem">
            <router-link
              :to="{ name: 'PersonelDetail', params: { id: row.item.id } }"
            >
              {{
                (tableItems[row.index].name +
                  " " +
                  tableItems[row.index].surname)
                  | capitalize
              }}
            </router-link>
          </div>
          <small
            class="text-danger"
            v-if="
              tableItems[row.index].branch &&
              tableItems[row.index].branch.clubId
            "
          >
            {{ tableItems[row.index].branch.clubs.name }}
          </small>
          <br />
          <small
            class="text-danger"
            v-if="
              tableItems[row.index].branch &&
              tableItems[row.index].branch.secondClubId
            "
          >
            {{ tableItems[row.index].branch.secondClubs.name }}
          </small>
        </template>

        <template #cell(names)="row">
          <div
            style="width: 24rem"
            v-for="user in tableItems[row.index].personels"
            :key="user.id"
          >
            <router-link
              v-if="editable"
              v-text="user.name + ' ' + user.surname"
              :to="{ name: 'PersonelDetail', params: { id: user.id } }"
            ></router-link>
            <span v-else>{{ user.name + " " + user.surname }}</span>
          </div>
          <small
            class="text-danger"
            v-if="
              tableItems[row.index].clubRel &&
              tableItems[row.index].clubRel.name
            "
          >
            {{ tableItems[row.index].clubRel.name }}
          </small>
        </template>
        <template #cell(sendername)="row">
          <div v-if="tableItems[row.index].sender">
            <router-link
              v-if="hasRole(['federation'])"
              :to="{
                name: 'PersonelDetail',
                params: { id: row.item.from },
              }"
            >
              {{
                tableItems[row.index].sender.name +
                " " +
                tableItems[row.index].sender.surname
              }}
            </router-link>
            <div v-else>
              {{
                tableItems[row.index].sender.name +
                " " +
                tableItems[row.index].sender.surname
              }}
            </div>
          </div>
          <div v-else>Kano Federasyonu</div>
        </template>
        <template #cell(receivername)="row">
          <div v-if="tableItems[row.index].receiver">
            <router-link
              v-if="hasRole(['federation'])"
              :to="{
                name: 'PersonelDetail',
                params: { id: row.item.to },
              }"
            >
              {{
                tableItems[row.index].receiver.name +
                " " +
                tableItems[row.index].receiver.surname
              }}
            </router-link>
            <div v-else>
              {{
                tableItems[row.index].receiver.name +
                " " +
                tableItems[row.index].receiver.surname
              }}
            </div>
          </div>
          <div v-else>Kano Federasyonu</div>
        </template>

        <template #cell(message)="row">
          <div style="width: 24rem">
            <router-link
              :to="{ name: 'MessageDetail', params: { id: row.item.id } }"
            >
              {{
                tableItems[row.index].message.substring(0, 100) +
                (tableItems[row.index].message.length > 99 ? "..." : "")
              }}
            </router-link>
          </div>
        </template>

        <template #cell(hasRead)="row">
          <div>
            {{ tableItems[row.index].hasRead ? "Okundu" : "Okunmadı" }}
          </div>
        </template>

        <template #cell(events)="row" v-if="hasRole(['federation'])">
          <b-button-group>
            <b-button
              v-if="hasAction == 'personel'"
              size="sm"
              v-b-modal.singleApproval
              @click="singleApprovalItem = row.index"
            >
              <b-icon-check></b-icon-check>
            </b-button>
            <b-button
              v-else-if="hasAction == 'competition'"
              size="sm"
              @click="matchesPage(row.index)"
            >
              <b-icon-calendar></b-icon-calendar>
            </b-button>
            <b-button
              v-else-if="hasAction == 'group'"
              size="sm"
              @click="groupDetail(row.index)"
            >
              <b-icon-search></b-icon-search>
            </b-button>
            <b-button
              v-if="editable == true"
              size="sm"
              @click="editItem(row.index)"
            >
              <b-icon-pencil></b-icon-pencil>
            </b-button>
            <b-button
              v-if="erasable == true"
              size="sm"
              v-b-modal.singleDeletion
              @click="singleDeletingItem = row.index"
              class="btn-danger"
            >
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-button-group>
        </template>
        <template #cell(events)="row" v-else-if="hasRole(['club'])">
          <b-button-group>
            <b-button
              v-if="erasable == true && fromClub"
              size="sm"
              v-b-modal.singleDeletion
              @click="singleDeletingItem = row.index"
              class="btn-danger"
            >
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-button-group>
        </template>
        <template #cell(events)="row" v-else-if="hasRole(['individual'])">
          <b-button-group>
            <b-button
              v-if="
                erasable == true &&
                !row.item.approved &&
                row.item.personel1.id != $store.getters.getUser.id
              "
              size="sm"
              v-b-modal.singleApproval
              @click="singleApprovalItem = row.index"
              class="btn-danger"
            >
              <b-icon-x></b-icon-x>
            </b-button>
            <b-button
              v-if="
                erasable == true &&
                !row.item.approved &&
                row.item.personel1.id == $store.getters.getUser.id
              "
              size="sm"
              v-b-modal.singleDeletion
              @click="singleDeletingItem = row.index"
              class="btn-danger"
            >
              <b-icon-trash></b-icon-trash>
            </b-button>
          </b-button-group>
        </template>

        <template #cell(id)="id">
          <b-badge variant="secondary">
            {{ id.index + 1 + (currentPage - 1) * maxTableItems }}
          </b-badge>
        </template>
      </b-table>

      <b-row
        align-v="center"
        align-h="start"
        class="ml-2"
        v-if="hasRole(['federation'])"
      >
        <p>Seçili olanları:</p>
        <b-col align-self="start">
          <b-form-group>
            <b-form-select
              id="table-select-mode-select"
              v-model="actionsSelectMode"
              :options="actionModes"
            ></b-form-select>
          </b-form-group>
        </b-col>
        <b-col align-self="start">
          <b-button v-b-modal.multipleAction>Tamam</b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import constants from "@/store/constants";
import roleOptions from "@/store/constants/roleOptions";
import cities from "@/store/constants/cities";
import { mapGetters } from "vuex";
export default {
  name: "PMSTable",
  props: {
    tableFields: Array,
    tableItems: Array,
    editOptions: Object,
    editable: Boolean,
    infinitePageItems: {
      type: Boolean,
      default: false,
    },
    erasable: Boolean,
    limit: Number,
    selectable: {
      type: Boolean,
      default: true,
    },
    actionModes: {
      type: Array,
      default: () => ["Sil"],
    },
    fromClub: {
      type: Boolean,
      default: false,
    },
    deleteMessage: String,
    approveMessage: {
      type: String,
      default: "Öğeyi onaylamak istediğinizden emin misiniz?",
    },
    hasAction: String,
    pageCount: Number,
    nameWithSurname: Boolean,
  },

  computed: {
    ...mapGetters({
      getCurrentPage: "getPMSTablePaginationCurrentPage",
      getManagementItems: "getManagementItems",
    }),
    currentPage: {
      get() {
        return this.getCurrentPage;
      },
      set(newName) {
        return this.$store.commit(
          "updatePMSTablePaginationCurrentPage",
          newName
        );
      },
    },

    rows() {
      return this.tableItems.length;
    },
  },
  data() {
    return {
      perPage: this.limit ? this.limit : constants.MAX_TABLE_ITEMS,
      actionsSelected: [],
      actionsSelectMode: "Sil",
      selected: [],
      singleDeletingItem: null,
      singleApprovalItem: null,
      maxTableItems: constants.MAX_TABLE_ITEMS,
      cities,

      roleOptions,
    };
  },
  mounted() {
    this.$store.dispatch("initManagement");
  },
  methods: {
    hasRole(roles) {
      let vals = [];
      roles.forEach((role) => {
        if (role == "federation") {
          vals.push(this.$store.getters.checkToken.isFederation);
        } else if (role == "personel") {
          vals.push(this.$store.getters.checkToken.isPersonel);
        } else if (role == "club") {
          vals.push(this.$store.getters.checkToken.isClub);
        } else if (role == "provincialRepresentative") {
          vals.push(this.$store.getters.checkToken.isProvincialRepresentative);
        } else if (role == "individual") {
          vals.push(this.$store.getters.checkToken.isIndividual);
        }
      });
      return vals.includes(true);
    },
    handlePageChange(value) {
      this.$emit("onPageChanged", value);
    },
    onRowSelected(tableItems) {
      this.selected = tableItems;
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },

    selectAllRows() {
      this.$refs.selectableTable.selectAllRows();
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },

    getSelectedItems() {
      return this.selected.map((e) => e.name);
    },
    matchesPage(id) {
      this.$emit("matchesPage", this.tableItems[id].id);
    },
    groupDetail(id) {
      this.$emit("groupDetail", this.tableItems[id].id);
    },
    deleteSingleItem() {
      this.$emit("multipleDeleteAction", [
        this.tableItems[this.singleDeletingItem].id,
      ]);
    },
    approveSingleItem() {
      this.$emit("multipleApproveAction", [
        this.tableItems[this.singleApprovalItem].id,
      ]);
    },
    editItem(id) {
      this.$emit("editClick", id);
    },
    printParticipantList(id) {
      this.$emit("printParticipantList", id);
    },
    openDetail(id) {
      this.$emit("openDetail", id);
    },
    openPersonelDetail(id, isReceiver) {
      this.$emit("openPersonelDetail", id, isReceiver);
    },
    applyAction() {
      switch (this.actionsSelectMode) {
        case "Sil":
          this.$emit(
            "multipleDeleteAction",
            this.selected.map((e) => e.id)
          );
          break;
        case "Onayla":
          this.$emit(
            "multipleApproveAction",
            this.selected.map((e) => e.id)
          );
          break;
      }
    },
    getActionName() {
      switch (this.actionsSelectMode) {
        case "Sil":
          return "silmek";
        case "Onayla":
          return "onaylamak";
      }
    },
    getCityName(city) {
      if (city.value) {
        return city.text;
      } else {
        return "Girilmemiş";
      }
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.split(" ").map((e) => e.replaceAll("I", "ı"));
      return value
        .map(
          (e) =>
            e.charAt(0).replaceAll("i", "İ").toUpperCase() +
            e.toLowerCase().slice(1)
        )
        .join(" ");
    },
  },
};
</script>

<style scoped>
.align-right {
  margin-right: 1rem;
}
</style>